.auth__link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-decoration: none;
}

.auth__link:hover {
  opacity: 0.8;
}
