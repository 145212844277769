.card__like-button {
  width: 21px;
  height: 19px;
  background: transparent;
  border: none;
  background-image: url(../../../images/like.svg);
}

.card__like-button:hover {
  cursor: pointer;
  opacity: 0.5;
}
