@import url(./__close/popup__close.css);
@import url(./__title/popup__title.css);

#lightbox {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}

#lightbox.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#lightbox img {
  max-width: 75vw;
  max-height: 75vh;
  background-color: black;
}
