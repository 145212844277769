.card__remove-button {
  width: 18px;
  height: 20px;
  position: absolute;
  margin-left: 250px;
  margin-top: 18px;
  background: transparent;
  border: none;
  background-image: url(../../../images/trash.svg);
  visibility: hidden;
}

.card__remove-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.card__remove-button_active {
  visibility: visible;
}
