@import url(./__line/header__line.css);
@import url(./__logo/header__logo.css);
@import url(./__auth/header__auth.css);
@import url(./__nav/header__nav.css);

.header {
  padding-top: 45px;
  max-width: 880px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .header {
    padding-top: 28px;
    width: 80vw;
  }
}
