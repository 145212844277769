.popup__close {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 270px;
  top: 29px;
  background-color: transparent;
  background-image: url(../../../images/close.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  border-style: none;
  padding: 0;
  z-index: 5;
}

.popup__close:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 540px) {
  .popup__close {
    width: 26px;
    height: 26px;
    right: -1px;
    top: -52px;
  }
}
