.modal__error {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  visibility: hidden;
  padding: 0px 0px 30px 0px;
  position: absolute;
}

.modal__error_visible {
  visibility: visible;
}
