.profile__title {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
  margin: 0;
  padding-top: 13px;
}

@media screen and (max-width: 460px) {
  .profile__title {
    font-size: 14px;
    line-height: 17px;
  }
}
