.profile__edit-button {
  border: 1px solid #fff;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.profile__edit-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 460px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
  }
}
