@import url(./vendor/normalize.css);
@import url(./vendor/fonts.css);

@import url(./blocks/main/main.css);
@import url(./blocks/header/header.css);
@import url(./blocks/profile/profile.css);
@import url(./blocks/cards/cards.css);
@import url(./blocks/card/card.css);
@import url(./blocks/footer/footer.css);
@import url(./blocks/modal/modal.css);
@import url(./blocks/modal-post/modal-post.css);
@import url(./blocks/popup/popup.css);
@import url(./blocks/page/page.css);

@import url(./blocks/auth/auth.css);

html {
  background-color: black;
}
