.profile__container-middle {
  display: flex;
  flex-direction: column;
  flex-grow: 10000;
}

@media screen and (max-width: 640px) {
  .profile__container-middle {
    padding-bottom: 36px;
  }
}
