.profile__edit-icon {
    width: 10px;
    height: 10px;
  }
  
  @media screen and (max-width: 460px) {
    .profile__edit-icon {
      width: 7.5px;
      height: 7.5px;
    }
  }
  