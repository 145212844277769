.auth__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 358px;
  margin: auto;
  padding-top: 60px;
}

@media screen and (max-width: 415px) {
  .auth__form {
    width: 80vw;
  }
}
