.modal__form-submit {
  background-color: #000;
  border-radius: 2px;
  border-style: none;
  color: #fff;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 36px;
  margin-top: 48px;
  width: 100%;
  padding-bottom: 14px;
  padding-top: 14px;
}

.modal__form-submit:hover {
  cursor: pointer;
  opacity: 0.8;
}

.modal__form-submit:disabled {
  opacity: 0.2;
  pointer-events: none;
  color: black;
  background-color: transparent;
  border: 1px solid black;
}
