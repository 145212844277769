@import url(./__container-left/profile__container-left.css);
@import url(./__container-middle/profile__container-middle.css);
@import url(./__container-right/profile__container-right.css);
@import url(./__edit-button/profile__edit-button.css);
@import url(./__edit-icon/profile__edit-icon.css);
@import url(./__img/profile__img.css);
@import url(./__name/profile__name.css);
@import url(./__post-button/profile__post-button.css);
@import url(./__post-icon/profile__post-icon.css);
@import url(./__subcontainer-top/profile__subcontainer-top.css);
@import url(./__title/profile__title.css);

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}

@media screen and (max-width: 640px) {
  .profile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
