.card__title {
  font-size: 24px;
  line-height: 29px;
  color: #000;
  font-family: "Inter";
  font-weight: 900;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 226px;
}
