.profile__name {
  font-family: "Inter";
  font-size: 42px;
  font-weight: 500;
  line-height: 48px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
}

@media screen and (max-width: 460px) {
  .profile__name {
    font-size: 22px;
    line-height: 27px;
  }
}
