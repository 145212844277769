.profile__container-left {
  flex-grow: 1;
  padding-right: 30px;
  position: relative;
}

@media screen and (max-width: 640px) {
  .profile__container-left {
    padding-right: 0;
    padding-bottom: 26.25px;
  }
}
