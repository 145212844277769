.footer__copyright {
  padding-top: 68px;
  padding-bottom: 60px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
}

@media screen and (max-width: 460px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
    padding-top: 50px;
    padding-bottom: 36px;
  }
}
