.profile__img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.profile__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 127px;
  height: 127px;
  border-radius: 50%;
  display: none;
}

.profile__overlay .profile__img-button {
  background-image: url(../../../images/edit-image.svg);
  width: 25px;
  height: 25px;
  display: block;
  margin: auto;
}

.profile__container-left:hover .profile__overlay {
  display: flex;
}
